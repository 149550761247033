<template>
  <!-- <div class="code-img" :style="`backgroundImage:url(${posterBg});backgroundSize:cover;`"></div> -->
  <div class="poster">
    <img class="poster-img" :src="posterBg" />
  </div>
</template>

<script>
  import * as Poster from '@/api/poster';
  export default {
    data() {
      return {
        posterBg: '',
      };
    },
    mounted() {
      if (this.$route.query.cmeId) {
        if (this.$route.query.from === 'draft') {
          this.getPosterDataPreview(this.$route.query.cmeId);
        } else {
          this.getPosterData(this.$route.query.cmeId);
        }
      }
    },
    methods: {
      async getPosterData(id) {
        try {
          const data = await Poster.getPosterById({ posterId: id });
          this.posterBg = data.compositeImg;
        } catch (error) {
          this.$toast(error.msg || '网络错误');
        }
      },
      async getPosterDataPreview(id) {
        try {
          const data = await Poster.getPosterPreview({ id: id });
          console.log(data);
          this.posterBg = data.content.compositeImg;
        } catch (error) {
          this.$toast(error.msg || '网络错误');
        }
      },
    },
  };
</script>

<style lang="less" scoped>
.poster {
  max-width: 100%;
  max-height: 100%;
}
.poster-img {
  width: 100%;
}
</style>
