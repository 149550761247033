import request from '../../util/request';
const version = 'v1';
// 根据id查询海报详情
export function getPosterById(data) {
    return request.get({
        url: `/bff/martech-cme/poster/${version}/query-poster`,
        params: { posterId: data.posterId }
    });
}
export function getPosterPreview(data) {
    return request.get({
        url: `/bff/martech-cme/draft/${version}/getDraft/${data.id}`
    });
}
